import React, { useMemo } from "react"

import { Product } from "@lavita-io/international-sdk"
import { formatPrice } from "../../../shared/utils/format"
import { useLocalesState } from "../../../shared/context/locales"
import i18n from "../../../i18n/config"

export interface UpsellingItemPriceAdditionProps {
    product?: Product
    realPrice?: boolean
}

const getPriceAddition = (product: Product, language: string, realPrice?: boolean) => {
    return (product.sku === "LV2START" || product.sku === "LVSTARTNA") && !realPrice
        ? i18n.t("upselling_price_addition", {
              basic_price: formatPrice(product.price.amount, product.price.currency, language),
          })
        : product.sku === "LV2START" && realPrice
        ? i18n.t("upselling_price_addition_real", {
              price: formatPrice(
                  product.price.amount / (product.splitted?.parts || 1),
                  product.price.currency,
                  language
              ),
          })
        : product.sku === "LVSTARTNA" && realPrice
        ? i18n.t("upselling_price_addition_real", {
              price: formatPrice(product.price.amount / 3, product.price.currency, language),
          })
        : undefined
}

const UpsellingItemPriceAddition = ({ product, realPrice }: UpsellingItemPriceAdditionProps) => {
    const { language } = useLocalesState()

    if (!product) return null

    const priceAddition = useMemo(() => getPriceAddition(product, language, realPrice), [product, realPrice, language])
    return <p className="text-right text-sm sm:text-base lg:text-md">{priceAddition}</p>
}

export default UpsellingItemPriceAddition
