import { Container, Spacer, SpacerVariant, spacerOptions } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import React from "react"

export interface IconTwoColumnsTextGridProps {
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
}

const IconTwoColumnsTextGrid: types.Brick<IconTwoColumnsTextGridProps> = ({
    spacerTop,
    spacerBottom,
    variantTop,
    variantBottom,
}) => {
    return (
        <Container>
            <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
            <Repeater propName="centerText" />
            <ul>
                <Repeater propName="repeater" />
            </ul>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
        </Container>
    )
}

IconTwoColumnsTextGrid.schema = {
    name: "IconTwoColumnsTextGrid",
    label: "Icon 2 Columns Text Grid",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/Icon2ColumnsTextGrid.png`,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "IconTwoColumnsText",
                    label: "Icon 2 Columns Text",
                    min: 1,
                    max: 10,
                },
            ],
        },
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
    ],
    getDefaultProps: (): IconTwoColumnsTextGridProps => ({
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Base,
        variantBottom: SpacerVariant.Base,
    }),
}

export default IconTwoColumnsTextGrid
