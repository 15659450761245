import { Spacer, SpacerVariant, spacerOptions } from "../components/layout"

import React from "react"
import { types } from "react-bricks/frontend"

export interface DividerProps {
    spacerTop?: boolean
    spacerBottom?: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
    text?: string
}

const Divider: types.Brick<DividerProps> = ({ spacerTop, spacerBottom, variantTop, variantBottom, text }) => {
    return (
        <>
            <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
            {text ? (
                <div className="mb-3.5 flex w-full items-center justify-center gap-x-3 pt-1">
                    <div className="h-px w-full bg-[#eaeaea]" />
                    <p className="text-xs uppercase lg:text-base">{text}</p>
                    <div className="h-px w-full bg-[#eaeaea]" />
                </div>
            ) : (
                <div className="h-px w-full bg-border-tide sm:h-0.5" />
            )}
            <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
        </>
    )
}

Divider.schema = {
    name: "Divider",
    label: "Divider",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/Divider.png`,
    sideEditProps: [
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
    ],
    getDefaultProps: (): DividerProps => ({
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Base,
        variantBottom: SpacerVariant.Base,
    }),
}

export default Divider
