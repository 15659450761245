import React, { useMemo } from "react"

import { EqualHeightElement } from "react-equal-height/clean"
import { Product } from "@lavita-io/international-sdk"
import { Text } from "react-bricks/frontend"
import { formatPrice } from "../../../shared/utils/format"
import i18n from "../../../i18n/config"
import { useLocalesState } from "../../../shared/context/locales"

export interface MatrixItemPriceProps {
    row: number
    product?: Product
}

const getPrice = (product: Product, language: string) => {
    return product.sku === "LV2START"
        ? formatPrice(product.price.amount / (product.splitted?.parts || 1), product.price.currency, language)
        : product.sku === "LVSTARTNA"
        ? formatPrice(product.price.amount / 3, product.price.currency, language)
        : product.subscription
        ? formatPrice(
              (product.price.amount * (product.subscription.max_quantity - 1)) / product.subscription.max_quantity,
              product.price.currency,
              language
          ) + "*"
        : formatPrice(product.price.amount, product.price.currency, language)
}

const getPriceAddition = (product: Product, language: string) => {
    return product.sku === "LV2START" || product.sku === "LVSTARTNA"
        ? product.price.unit + " " + formatPrice(product.price.amount, product.price.currency, language)
        : undefined
}

const getSmallprint = (product: Product, language: string) => {
    return !["Liter", "litre"].includes(product.basic_price.unit)
        ? i18n.t("price_incl_vat")
        : product.subscription
        ? i18n.t("price_per_litre_subscription_asterisk", {
              basic_price: formatPrice(product.basic_price.amount, product.price.currency, language),
              amount: product.subscription.max_quantity,
              price: formatPrice(product.price.amount, product.price.currency, language),
              discount_price: formatPrice(
                  (product.price.amount * (product.subscription.max_quantity - 1)) / product.subscription.max_quantity,
                  product.price.currency,
                  language
              ),
          })
        : i18n.t("price_per_litre", {
              price: formatPrice(product.basic_price.amount, product.price.currency, language),
          })
}

const MatrixItemPrice = ({ row, product }: MatrixItemPriceProps) => {
    const { language } = useLocalesState()

    if (!product) return null

    const price = useMemo(() => getPrice(product, language), [product, language])
    const priceAddition = useMemo(() => getPriceAddition(product, language), [product, language])
    const smallprint = useMemo(() => getSmallprint(product, language), [product, language])

    return (
        <div className="pb-7 pt-3">
            <EqualHeightElement name={"price-" + row}>
                <Text
                    propName="label"
                    renderBlock={({ children }) => <p className="mb-1 text-sm text-dark-grey">{children}</p>}
                    placeholder="Lorem ipsum"
                />
                <div className="mb-3.5 flex items-end gap-3 sm:mb-6">
                    <p className="text-base font-semibold md:text-md">{price}</p>
                    {priceAddition && <p className="text-sm">{priceAddition}</p>}
                </div>
                <small className="text-box whitespace-pre-wrap text-xs text-dark-grey">{smallprint}</small>
            </EqualHeightElement>
        </div>
    )
}

export default MatrixItemPrice
