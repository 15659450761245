import { Background, BackgroundColor, Container, Spacer, SpacerVariant, spacerOptions } from "../components/layout"
import ImageEditor, { AspectRatio } from "../Image"
import React, { useState } from "react"
import { Repeater, Text, types, usePageValues } from "react-bricks/frontend"

import Badge from "../components/trust/badge"
import { ButtonBrickProps } from "../button/Button"
import { ButtonSize } from "../components/button"
import { Product } from "@lavita-io/international-sdk"
import Trust from "./pdp/Trust"
import TrustedShopBadge from "../components/trust/trusted-shop-badge"
import { UpsellingItemProps } from "./upselling/Item"
import client from "../../shared/utils/client"
import { useClientState } from "../../shared/context/client"
import { useLocalesState } from "../../shared/context/locales"
import { usePageContext } from "../../context/page"
import { useQuery } from "@tanstack/react-query"
import classNames from "classnames"

export interface UpsellingProps {
    repeater?: {
        id: string
        props: UpsellingItemProps
        type: "UpsellingItem"
    }[]
    repeaterBottom1?: {
        id: string
        props: ButtonBrickProps
        type: "Button"
    }[]
    repeaterBottom2?: {
        id: string
        props: ButtonBrickProps
        type: "Button"
    }[]
    repeaterBottom3?: {
        id: string
        props: ButtonBrickProps
        type: "Button"
    }[]
    sku?: string
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
    id?: string
    trustBadge?: boolean
    hideImage?: boolean
    index?: number
}

const Upselling: types.Brick<UpsellingProps> = ({
    repeater,
    spacerTop,
    spacerBottom,
    variantTop = SpacerVariant.Small,
    variantBottom = SpacerVariant.Small,
    trustBadge,
    hideImage,
    id,
    index,
}) => {
    const { products: productsBuildTime, page: pageContext } = usePageContext()
    const { consumerToken } = useClientState()
    const { language, region } = useLocalesState()

    const [pageValues] = usePageValues()

    const page = pageContext || pageValues

    const { data } = useQuery<Product[]>(
        ["products", ...(repeater?.map(r => r.props.sku).filter(r => r) || []), region, page?.language],
        () =>
            client.getProducts(
                { sku: repeater?.map(r => r.props.sku).filter(r => r), country: region },
                page?.language
            ),
        {
            enabled: !!(repeater?.length && page && consumerToken),
        }
    )

    const products = data || productsBuildTime

    const [selectedProduct, setSelectedProduct] = useState(0)

    const changeSelectedProduct = (index: number) => {
        setSelectedProduct(index)
    }

    return (
        <div key={index}>
            <Background color={BackgroundColor.White} className="overflow-hidden">
                <div id={id}>
                    <Container>
                        <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
                        <Repeater propName="headline" />
                        <div className="hidden sm:block">
                            <Repeater propName="checkList" />
                        </div>
                        {trustBadge && (
                            <div className="hidden sm:block">
                                <Trust spacerBottom={false} spacerTop />
                            </div>
                        )}
                    </Container>
                    <div className="mt-7 sm:mt-10 lg:mt-20">
                        {!hideImage && (
                            <ImageEditor
                                propName="imageMobile"
                                aspectRatio={AspectRatio["3/2"]}
                                className="w-full sm:hidden"
                            />
                        )}
                        <Container>
                            <div
                                className={classNames(
                                    "grid w-full grid-cols-1 gap-x-10 lg:gap-x-16 xl:gap-x-20",
                                    hideImage ? "sm:grid-cols-1" : "sm:grid-cols-[auto_1fr]"
                                )}
                            >
                                {!hideImage && (
                                    <ImageEditor
                                        propName="imageDesktop"
                                        aspectRatio={AspectRatio["1/1"]}
                                        maxWidth={708}
                                        className="hidden max-w-80 rounded-base sm:block md:max-w-[462px] lg:max-w-[530px] xl:max-w-[708px]"
                                    />
                                )}
                                {trustBadge && (
                                    <div className={classNames("sm:hidden", hideImage ? "mt-0" : "mt-5")}>
                                        {language === "de" && region === "DE" ? (
                                            <TrustedShopBadge />
                                        ) : (
                                            <Badge size="sm" />
                                        )}
                                    </div>
                                )}
                                <div className={classNames("mt-5 sm:mt-0", { "mx-auto sm:w-[652px]": hideImage })}>
                                    <ul>
                                        <Repeater
                                            propName="repeater"
                                            itemProps={
                                                {
                                                    products,
                                                    changeSelectedProduct,
                                                    selectedProduct,
                                                } as UpsellingItemProps
                                            }
                                        />
                                    </ul>
                                    <div className="pt-1.5">
                                        {selectedProduct === 0 ? (
                                            <Repeater
                                                propName="bottomRepeater1"
                                                itemProps={{ fullWidth: true, size: ButtonSize.Small }}
                                            />
                                        ) : selectedProduct === 1 ? (
                                            <Repeater
                                                propName="bottomRepeater2"
                                                itemProps={{ fullWidth: true, size: ButtonSize.Small }}
                                            />
                                        ) : (
                                            <Repeater
                                                propName="bottomRepeater3"
                                                itemProps={{ fullWidth: true, size: ButtonSize.Small }}
                                            />
                                        )}
                                    </div>
                                    <Text
                                        propName="vat-text"
                                        renderBlock={({ children }) => (
                                            <p className="mt-5 text-sm sm:text-base lg:mt-6 lg:text-md">{children}</p>
                                        )}
                                        placeholder="Lorem ipsum"
                                    />
                                    <div className="sm:hidden">
                                        <Repeater propName="checkList" />
                                    </div>
                                </div>
                            </div>
                            <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
                        </Container>
                    </div>
                </div>
            </Background>
        </div>
    )
}

Upselling.schema = {
    name: "Upselling",
    label: "Upselling",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/Upselling.png`,
    repeaterItems: [
        {
            name: "headline",
            items: [
                {
                    type: "Headline",
                    label: "Headline",
                    max: 1,
                },
            ],
        },
        {
            name: "checkList",
            items: [
                {
                    type: "CheckList",
                    label: "CheckList",
                    max: 1,
                },
            ],
        },
        {
            name: "repeater",
            items: [
                {
                    type: "UpsellingItem",
                    label: "Upselling Item",
                    min: 2,
                    max: 3,
                },
            ],
        },
        {
            name: "bottomRepeater1",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    min: 1,
                    max: 1,
                },
            ],
        },
        {
            name: "bottomRepeater2",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    min: 1,
                    max: 1,
                },
            ],
        },
        {
            name: "bottomRepeater3",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    min: 1,
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [...spacerOptions].sort((a, b) => (a.value === SpacerVariant.Small ? -1 : 1)),
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [...spacerOptions].sort((a, b) => (a.value === SpacerVariant.Small ? -1 : 1)),
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
        {
            name: "trustBadge",
            label: "Trust Badge",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "hideImage",
            label: "Hide Image",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
    ],
    getDefaultProps: (): UpsellingProps => ({
        trustBadge: true,
        hideImage: false,
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Small,
        variantBottom: SpacerVariant.Small,
    }),
}

export default Upselling
