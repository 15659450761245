import {
    Align,
    Color,
    MaxWidth,
    TypographyAlign,
    TypographyColor,
    TypographyMaxWidth,
    alignOptions,
    colorOptions,
} from "../components/typography"
import {
    Background,
    BackgroundColor,
    Container,
    Spacer,
    SpacerVariant,
    backgroundGreyScaleColorsOptions,
} from "../components/layout"
import ImageEditor, { AspectRatio } from "../Image"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import { ButtonBrickProps } from "../button/Button"
import { ButtonStyle } from "../components/button"
import React from "react"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import { useCheckForEmptyText } from "../../hooks/use-check-for-empty-text"

interface FullWidthImageBreakProps {
    overlap: TypographyElement[]
    text: TypographyElement[]
    align: TypographyAlign
    color: { color: TypographyColor } | TypographyColor
    textBelowImage: boolean
    background: { color: BackgroundColor } | BackgroundColor
}

const FullWidthImageBreak: types.Brick<FullWidthImageBreakProps> = ({
    align,
    color,
    textBelowImage,
    overlap,
    text,
    background,
}) => {
    const emptyOverlap = useCheckForEmptyText(overlap)
    const emptyText = useCheckForEmptyText(text)

    const bgColor = typeof background === "object" ? background.color : background
    const txtColor = typeof color === "object" ? color.color : color

    return (
        <Background color={bgColor}>
            <div className="relative">
                <ImageEditor
                    maxWidth={1920}
                    aspectRatio={AspectRatio["5/2"]}
                    className="hidden w-full sm:aspect-h-2 sm:aspect-w-5 sm:m-0 sm:block sm:p-0"
                    propName="image"
                />
                <ImageEditor
                    maxWidth={768}
                    aspectRatio={AspectRatio["5/4"]}
                    className="aspect-h-4 aspect-w-5 m-0 w-full object-cover p-0 sm:hidden"
                    propName="image_mobile"
                />
                {(!emptyText || !emptyOverlap) && (
                    <div className="absolute top-1/2 w-full -translate-y-1/2 justify-center">
                        <Container>
                            <Align align={align}>
                                <MaxWidth
                                    smMaxWidth={TypographyMaxWidth.Full}
                                    mdMaxWidth={
                                        align === TypographyAlign.Center
                                            ? TypographyMaxWidth["70%"]
                                            : TypographyMaxWidth.Half
                                    }
                                >
                                    <Color color={txtColor}>
                                        {!emptyOverlap && (
                                            <Typography
                                                allowList={["h2", "h3", "sup", "link", "bold"]}
                                                propName="overlap"
                                            />
                                        )}
                                        {!emptyText && (
                                            <div
                                                className={classNames({
                                                    "mt-5 lg:mt-7": !emptyOverlap,
                                                    "hidden sm:block": !textBelowImage,
                                                    "hidden md:block": textBelowImage,
                                                })}
                                            >
                                                <Typography
                                                    allowList={[
                                                        "h2",
                                                        "h3",
                                                        "ul",
                                                        "small",
                                                        "sup",
                                                        "link",
                                                        "bold",
                                                        "checkList",
                                                    ]}
                                                />
                                            </div>
                                        )}
                                    </Color>
                                </MaxWidth>
                                <div
                                    className={twMerge(
                                        classNames({
                                            "hidden sm:block": !textBelowImage,
                                            "hidden md:block": textBelowImage,
                                            block: emptyText,
                                        })
                                    )}
                                >
                                    <Repeater
                                        propName="repeater"
                                        renderWrapper={children => (
                                            <div
                                                className={classNames("mt-7 flex space-x-2 md:mt-10 md:space-x-2.5", {
                                                    "justify-center": align === TypographyAlign.Center,
                                                })}
                                            >
                                                {children}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Align>
                        </Container>
                    </div>
                )}
            </div>
            {!emptyText && (
                <div className={classNames({ "sm:hidden": !textBelowImage, "md:hidden": textBelowImage })}>
                    <Container>
                        <Spacer variant={SpacerVariant.Small} />
                        <Align align={TypographyAlign.Left}>
                            <Color>
                                <Typography
                                    allowList={["h2", "h3", "ul", "small", "sup", "link", "bold", "checkList"]}
                                />
                            </Color>
                        </Align>
                        <Repeater
                            propName="repeater"
                            renderWrapper={children => (
                                <div className="mt-7 flex space-x-2 md:space-x-2.5">{children}</div>
                            )}
                            itemProps={{ style: ButtonStyle.Primary } as ButtonBrickProps}
                        />
                        <Spacer variant={SpacerVariant.Small} />
                    </Container>
                </div>
            )}
        </Background>
    )
}

FullWidthImageBreak.schema = {
    name: "FullWidthImageBreak",
    label: "Full Width Image Break",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/FullWidthImageBreak.png`,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "ImageButton",
                    label: "ImageButton",
                    max: 2,
                },
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "align",
            label: "Align",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: alignOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "color",
            label: "Color",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: colorOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            name: "textBelowImage",
            label: "Text below Image on Tablet Portrait",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundGreyScaleColorsOptions,
                display: types.OptionsDisplay.Color,
            },
        },
    ],
    getDefaultProps: (): FullWidthImageBreakProps => ({
        overlap: [
            {
                type: "h2",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    },
                ],
            },
        ],
        align: TypographyAlign.Center,
        color: { color: TypographyColor.White },
        textBelowImage: false,
        background: { color: BackgroundColor.White },
    }),
}

export default FullWidthImageBreak
