import { Container, Spacer, SpacerVariant, spacerOptions } from "../components/layout"
import ImageEditor, { AspectRatio } from "../Image"
import { Repeater, types } from "react-bricks/frontend"

import { VideoPlayer as Player } from "../components/video"
import React from "react"

interface VideoPlayerProps {
    cloudflareVideoId: string
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
    isDecorativeVideo?: boolean
}

const VideoPlayer: types.Brick<VideoPlayerProps> = ({
    cloudflareVideoId,
    spacerTop,
    spacerBottom,
    variantTop,
    variantBottom,
    isDecorativeVideo,
}) => {
    return (
        <Container>
            <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
            <Repeater propName="centerText" />
            <Player cloudflareVideoId={cloudflareVideoId} isDecorativeVideo={isDecorativeVideo}>
                <ImageEditor
                    aspectRatio={AspectRatio["16/9"]}
                    maxWidth={1440}
                    propName="image"
                    className="w-full overflow-hidden rounded-base"
                />
            </Player>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
        </Container>
    )
}

VideoPlayer.schema = {
    name: "VideoPlayer",
    label: "Video Player",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/VideoPlayer.png`,
    repeaterItems: [
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "cloudflareVideoId",
            label: "Cloudflare Video Id",
            type: types.SideEditPropType.Text,
        },
        {
            name: "isDecorativeVideo",
            label: "Is Decorative Video",
            type: types.SideEditPropType.Boolean,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
    ],
    getDefaultProps: (): VideoPlayerProps => ({
        cloudflareVideoId: "",
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Base,
        variantBottom: SpacerVariant.Base,
        isDecorativeVideo: false,
    }),
}

export default VideoPlayer
