import { Align, Color, MaxWidth, TypographyAlign, TypographyColor, TypographyMaxWidth } from "../components/typography"
import { Container, Spacer, SpacerVariant } from "../components/layout"
import Image, { AspectRatio } from "../Image"
import Typography, { TypographyElement } from "../Typography"

import React from "react"
import { types } from "react-bricks/frontend"

interface TextCenterImageProps {
    headline: TypographyElement[]
    text: TypographyElement[]
}

const TextCenterImage: types.Brick<TextCenterImageProps> = () => {
    return (
        <>
            <div className="flex flex-col items-center bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#CCB9A6] to-[#9D8C7B]">
                <Spacer />
                <Container>
                    <Align align={TypographyAlign.Center}>
                        <MaxWidth smMaxWidth={TypographyMaxWidth.Full} mdMaxWidth={TypographyMaxWidth["70%"]}>
                            <Color color={TypographyColor.White}>
                                <Typography propName="headline" allowList={["h3", "sup", "link"]} />
                                <div className="mt-5 hidden sm:block lg:mt-7">
                                    <Typography allowList={["ul", "small", "sup", "link", "bold", "checkList"]} />
                                </div>
                            </Color>
                        </MaxWidth>
                        <Spacer variant={SpacerVariant.Medium} />
                    </Align>
                </Container>
                <div className="box-content max-w-[200px] px-6.5 sm:px-0 md:max-w-[340px] lg:max-w-[350px] xl:max-w-[33%]">
                    <Image maxWidth={670} aspectRatio={AspectRatio["1/1"]} />
                </div>
            </div>
            <div className="sm:hidden">
                <Spacer variant={SpacerVariant.Small} />
                <Container>
                    <Color>
                        <Typography allowList={["ul", "small", "sup", "link", "bold", "checkList"]} />
                    </Color>
                </Container>
                <Spacer />
            </div>
        </>
    )
}

TextCenterImage.schema = {
    name: "TextCenterImage",
    label: "Text Center Image",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/TextCenterImage.png`,
    getDefaultProps: (): TextCenterImageProps => ({
        headline: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing.",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.",
                    },
                ],
            },
        ],
    }),
}

export default TextCenterImage
