type SitemapLink = {
    id: string
    translations: {
        language: string
        title: string
        url: string
    }[]
}

export type Sitemap = {
    id: string
    translations: {
        language: string
        title: string
    }[]
    links: SitemapLink[]
}

export const sitemapMenu: Sitemap[][] = [
    [
        {
            id: "sitemap",
            translations: [
                {
                    language: "en",
                    title: "Sitemap",
                },
            ],
            links: [
                {
                    id: "micronutrient-concentrate",
                    translations: [
                        {
                            language: "en",
                            title: "Micronutrient concentrate",
                            url: "https://shop.lavita.com",
                        },
                    ],
                },
                {
                    id: "vital-substance-concentrate",
                    translations: [
                        {
                            language: "en",
                            title: "Vital substance concentrate",
                            url: "https://shop.lavita.com",
                        },
                    ],
                },
                {
                    id: "lavita-application",
                    translations: [
                        {
                            language: "en",
                            title: "LaVita application",
                            url: "https://www.lavita.com/service/how-to-use",
                        },
                    ],
                },
                {
                    id: "micronutrient-build-up-cure",
                    translations: [
                        {
                            language: "en",
                            title: "Micronutrient build-up cure",
                            url: "https://www.lavita.com/service/how-to-use",
                        },
                    ],
                },
                {
                    id: "bioavailability",
                    translations: [
                        {
                            language: "en",
                            title: "Bioavailability",
                            url: "https://www.lavita.com/micronutrient-concentrate/study/bioavailability",
                        },
                    ],
                },
                {
                    id: "lavita-juice",
                    translations: [
                        {
                            language: "en",
                            title: "LaVita juice",
                            url: "https://shop.lavita.com",
                        },
                    ],
                },
                {
                    id: "full-value-concentrate",
                    translations: [
                        {
                            language: "en",
                            title: "Full value concentrate",
                            url: "https://www.lavita.com/micronutrient-concentrate/why-lavita",
                        },
                    ],
                },
            ],
        },
        {
            id: "immunsystem",
            translations: [
                {
                    language: "de",
                    title: "Immunsystem",
                },
            ],
            links: [
                {
                    id: "immunsystem-stärken-bei-kindern",
                    translations: [
                        {
                            language: "de",
                            title: "Immunsystem stärken bei Kindern",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/immunsystem/immunsystem-staerken-kinder",
                        },
                    ],
                },
                {
                    id: "schwaches-immunsystem",
                    translations: [
                        {
                            language: "de",
                            title: "Schwaches Immunsystem",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/immunsystem/schwaches-immunsystem",
                        },
                    ],
                },
                {
                    id: "vitamine-fuer-das-immunsystem",
                    translations: [
                        {
                            language: "de",
                            title: "Vitamine für das Immunsystem",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/immunsystem/vitamine-fuer-immunsystem",
                        },
                    ],
                },
                {
                    id: "immunsystem-in-der-schwangerschaft",
                    translations: [
                        {
                            language: "de",
                            title: "Immunsystem in der Schwangerschaft",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/immunsystem/immunsystem-schwangerschaft",
                        },
                    ],
                },
                {
                    id: "was-ist-gut-fuer-das-immunsystem",
                    translations: [
                        {
                            language: "de",
                            title: "Was ist gut für das Immunsystem?",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/immunsystem/immunsystem-staerken",
                        },
                    ],
                },
            ],
        },
        {
            id: "muedigkeit",
            translations: [
                {
                    language: "de",
                    title: "Müdigkeit",
                },
            ],
            links: [
                {
                    id: "test-warum-bin-ich-so-muede",
                    translations: [
                        {
                            language: "de",
                            title: "Test: Warum bin ich so müde?",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/muedigkeit/warum-bin-ich-so-muede-test",
                        },
                    ],
                },
                {
                    id: "staendig-muede",
                    translations: [
                        {
                            language: "de",
                            title: "Ständig Müde",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/muedigkeit",
                        },
                    ],
                },
                {
                    id: "antriebslosigkeit",
                    translations: [
                        {
                            language: "de",
                            title: "Antriebslosigkeit",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/muedigkeit/antriebslosigkeit-ueberwinden",
                        },
                    ],
                },
                {
                    id: "was-hilft-gegen-muedigkeit",
                    translations: [
                        {
                            language: "de",
                            title: "Was hilft gegen Müdigkeit?",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/muedigkeit/was-tun-gegen-muedigkeit",
                        },
                    ],
                },
                {
                    id: "vitamine-gegen-muedigkeit",
                    translations: [
                        {
                            language: "de",
                            title: "Vitamine gegen Müdigkeit",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/muedigkeit/vitamine-gegen-muedigkeit",
                        },
                    ],
                },
            ],
        },
        {
            id: "schwangerschaft",
            translations: [
                {
                    language: "de",
                    title: "Schwangerschaft",
                },
            ],
            links: [
                {
                    id: "schwangerschaftsanzeichen",
                    translations: [
                        {
                            language: "de",
                            title: "Schwangerschaftsanzeichen",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/schwangerschaft/schwangerschaftsanzeichen",
                        },
                    ],
                },
                {
                    id: "gewichtszunahme-schwangerschaft",
                    translations: [
                        {
                            language: "de",
                            title: "Gewichtszunahme Schwangerschaft",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/schwangerschaft/gewichtszunahme-schwangerschaft",
                        },
                    ],
                },
                {
                    id: "positiver-schwangerschaftstest",
                    translations: [
                        {
                            language: "de",
                            title: "Positiver Schwangerschaftstest",
                            url: "https://www.lavita.com/de-ch/gesundheitswelt/schwangerschaft/positiver-schwangerschaftstest",
                        },
                    ],
                },
                {
                    id: "fruehschwangerschaft",
                    translations: [
                        {
                            language: "de",
                            title: "Frühschwangerschaft",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/schwangerschaft/fruehschwangerschaft",
                        },
                    ],
                },
                {
                    id: "was-darf-man-in-der-schwangerschaft-nicht-essen",
                    translations: [
                        {
                            language: "de",
                            title: "Was darf man in der Schwangerschaft nicht essen?",
                            url: "https://www.lavita.com/de-de/gesundheitswelt/schwangerschaft/was-darf-man-in-der-schwangerschaft-nicht-essen",
                        },
                    ],
                },
            ],
        },
        {
            id: "mikronaehrstoffe-vitamine-spurenelemente",
            translations: [
                {
                    language: "de",
                    title: "Mikronährstoffe (Vitamine & Spurenelemente)",
                },
            ],
            links: [
                {
                    id: "mikronaehrstoffe",
                    translations: [
                        {
                            language: "de",
                            title: "Mikronährstoffe",
                            url: "https://www.lavita.com/de-de",
                        },
                    ],
                },
                {
                    id: "mikronaehrstoffe-kaufen",
                    translations: [
                        {
                            language: "de",
                            title: "Mikronährstoffe kaufen",
                            url: "https://shop.lavita.com/de",
                        },
                    ],
                },
                {
                    id: "mikronaehrstoffkonzentrat",
                    translations: [
                        {
                            language: "de",
                            title: "Mikronährstoffkonzentrat",
                            url: "https://www.lavita.com/de-de",
                        },
                    ],
                },
                {
                    id: "naehrstoffkonzentrat",
                    translations: [
                        {
                            language: "de",
                            title: "Nährstoffkonzentrat",
                            url: "https://www.lavita.com/de-de",
                        },
                    ],
                },
                {
                    id: "vitamine-zum-trinken",
                    translations: [
                        {
                            language: "de",
                            title: "Vitamine zum Trinken",
                            url: "https://www.lavita.com/de-de",
                        },
                    ],
                },
                {
                    id: "vitaminkonzentrat",
                    translations: [
                        {
                            language: "de",
                            title: "Vitaminkonzentrat",
                            url: "https://www.lavita.com/de-de",
                        },
                    ],
                },
                {
                    id: "vitaminsaft",
                    translations: [
                        {
                            language: "de",
                            title: "Vitaminsaft",
                            url: "https://www.lavita.com/de-de",
                        },
                    ],
                },
            ],
        },
    ],
    [
        {
            id: "social-media",
            translations: [
                {
                    language: "de",
                    title: "LaVita auf Social Media, Bewertungsportalen & App Stores",
                },
            ],
            links: [
                {
                    id: "instagram",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf Instagram",
                            url: "https://www.instagram.com/lavita_de/",
                        },
                    ],
                },
                {
                    id: "facebook",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf Facebook ",
                            url: "https://de-de.facebook.com/pg/lavita/reviews/",
                        },
                    ],
                },
                {
                    id: "youtube",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf YouTube",
                            url: "https://www.youtube.com/channel/UCK-A3JZXLqoLpq61ccbmVJw",
                        },
                    ],
                },
                {
                    id: "pinterest",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf Pinterest",
                            url: "https://www.pinterest.de/lavita/",
                        },
                    ],
                },
                {
                    id: "linkedin",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf LinkedIn",
                            url: "https://www.linkedin.com/company/lavita-gmbh/",
                        },
                    ],
                },
                {
                    id: "xing",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf Xing",
                            url: "https://www.xing.com/pages/lavitagmbh",
                        },
                    ],
                },
                {
                    id: "kununu",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf Kununu",
                            url: "https://www.kununu.com/de/lavita",
                        },
                    ],
                },
                {
                    id: "trusted-shops",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf Trusted Shops",
                            url: "https://www.trustedshops.de/bewertung/info_X49E710FC236D0FD6604F8259314C8A7F.html",
                        },
                    ],
                },
                {
                    id: "trustpilot",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf Trustpilot",
                            url: "https://de.trustpilot.com/review/lavita.de",
                        },
                    ],
                },
                {
                    id: "app-store",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf App Store",
                            url: "https://apps.apple.com/de/app/lavita/id1484793998",
                        },
                    ],
                },
                {
                    id: "google-play",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita auf Google Play",
                            url: "https://play.google.com/store/apps/details?id=de.lavita.lavitaapp",
                        },
                    ],
                },
            ],
        },
        {
            id: "informationen",
            translations: [
                {
                    language: "de",
                    title: "Informationen zu LaVita",
                },
            ],
            links: [
                {
                    id: "lavita-alternative",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Alternative",
                            url: "https://www.lavita.com/de-de/alternativen",
                        },
                    ],
                },
                {
                    id: "lavita-bei-dm",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita bei dm",
                            url: "https://www.lavita.com/de-de/wo-kann-ich-lavita-kaufen",
                        },
                    ],
                },
                {
                    id: "kundenstimmen",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Erfahrungen",
                            url: "https://www.lavita.com/de-de/mikronaehrstoffkonzentrat/kundenstimmen",
                        },
                    ],
                },
                {
                    id: "lavita-erfahrungen",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Erfahrungen",
                            url: "https://www.lavita-erfahrungen.de/",
                        },
                    ],
                },
                {
                    id: "lavita-inhaltsstoffe",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Inhaltsstoffe",
                            url: "https://www.lavita.com/de-de/mikronaehrstoffkonzentrat/naehrwerte",
                        },
                    ],
                },
                {
                    id: "wo-kann-ich-lavita-kaufen",
                    translations: [
                        {
                            language: "de",
                            title: "Wo kann ich LaVita kaufen?",
                            url: "https://www.lavita.com/de-de/wo-kann-ich-lavita-kaufen",
                        },
                    ],
                },
            ],
        },
    ],
    [
        {
            id: "lavita-in-den-medien",
            translations: [
                {
                    language: "de",
                    title: "LaVita in den Medien",
                },
            ],
            links: [
                {
                    id: "welt-alternative",
                    translations: [
                        {
                            language: "de",
                            title: "Gibt es eine günstige Alternative zu LaVita?",
                            url: "https://unternehmen.welt.de/essen-trinken/lavita-alternative-vitalstoffkonzentrat.html",
                        },
                    ],
                },
                {
                    id: "morgenpost-alternative",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Alternative",
                            url: "https://www.morgenpost.de/advertorials/lavita-alternative.html",
                        },
                    ],
                },
                {
                    id: "eatsmarter-erfahrungen",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Erfahrungen",
                            url: "https://eatsmarter.de/ernaehrung/gesund-ernaehren/lavita-erfahrungen",
                        },
                    ],
                },
                {
                    id: "amazon-erfahrungen",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Erfahrungen",
                            url: "https://www.amazon.de/gp/customer-reviews/R11CWEOFUJ03ZW/",
                        },
                    ],
                },
                {
                    id: "wiwo-test",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Erfahrungen & Test",
                            url: "https://wiwo.de/adv/firmen/lavita-test.html",
                        },
                    ],
                },
                {
                    id: "falstaff-test",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Erfahrungen & Test",
                            url: "https://www.falstaff.com/at/news/lavita-erfahrungen-test",
                        },
                    ],
                },
                {
                    id: "handelsblatt",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Mikronährstoffkonzentrat",
                            url: "https://www.handelsblatt.com/adv/firmen/lavita-im-test-die-wahrheit-vorteile-und-nachteile.html",
                        },
                    ],
                },
                {
                    id: "ntv",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Mikronährstoffkonzentrat",
                            url: "https://unternehmen.n-tv.de/lavita-mikronaehrstoff-konzentrat.html",
                        },
                    ],
                },
                {
                    id: "morgenpost-test",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Mikronährstoffkonzentrat im Test",
                            url: "https://www.morgenpost.de/advertorials/lavita-mikronaehrstoffkonzentrat-im-test.html",
                        },
                    ],
                },
                {
                    id: "morgenpost-kaufen",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Mikronährstoffkonzentrat kaufen",
                            url: "https://morgenpost.de/advertorials/lavita-mikronaehrstoffkonzentrat-kaufen.html",
                        },
                    ],
                },
                {
                    id: "wirtschaft-magazin-test",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Saft im Test",
                            url: "https://wirtschaft-magazin.de/unternehmen/lavita-saft.html",
                        },
                    ],
                },
                {
                    id: "focus-erfahrungen",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Saft Erfahrungen",
                            url: "https://unternehmen.focus.de/lavita-saft-erfahrungen.html",
                        },
                    ],
                },
                {
                    id: "nzz-kaufen",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Saft kaufen",
                            url: "https://www.nzz.ch/promoted-content/lavita-saft-kaufen-ld.1693482",
                        },
                    ],
                },
                {
                    id: "focus-test",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Vitaminkonzentrat im Test",
                            url: "https://unternehmen.focus.de/lavita-vitalstoffkonzentrat-im-test.html",
                        },
                    ],
                },
                {
                    id: "waz-kritik",
                    translations: [
                        {
                            language: "de",
                            title: "LaVita Mikronährstoffkonzentrat Kritik",
                            url: "https://waz.de/advertorials/lavita-mikronaehrstoffkonzentrat-kritik.html",
                        },
                    ],
                },
                {
                    id: "ecowoman-test",
                    translations: [
                        {
                            language: "de",
                            title: "Mikronährstoffkonzentrat Test",
                            url: "https://www.ecowoman.de/koerper-geist/gesundheit/alles-wichtige-ueber-mikronaehrstoffkonzentrate-inklusive-test-9757",
                        },
                    ],
                },
                {
                    id: "gesundheit-test",
                    translations: [
                        {
                            language: "de",
                            title: "Mikronährstoffkonzentrat Test",
                            url: "https://www.gesundheit.de/ernaehrung/naehrstoffe/mikronaehrstoffkonzentrate-test-id213368/",
                        },
                    ],
                },
                {
                    id: "focus-kaufen",
                    translations: [
                        {
                            language: "de",
                            title: "Wo kann ich LaVita kaufen?",
                            url: "https://unternehmen.focus.de/wo-kann-ich-lavita-kaufen.html",
                        },
                    ],
                },
                {
                    id: "fr-kaufen",
                    translations: [
                        {
                            language: "de",
                            title: "Wo kann ich LaVita kaufen?",
                            url: "https://partner.fr.de/lavita-kaufen-bestellen.html",
                        },
                    ],
                },
                {
                    id: "fitforfun-kaufen",
                    translations: [
                        {
                            language: "de",
                            title: "Wo kann ich LaVita kaufen?",
                            url: "https://unternehmen.fitforfun.de/lavita-kaufen.html",
                        },
                    ],
                },
            ],
        },
    ],
]
