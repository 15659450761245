import { Background, BackgroundColor, Container, Spacer, SpacerVariant } from "../components/layout"

import { Button } from "../components/button"
import { Icons } from "../../types"
import React from "react"
import { useLocalesState } from "../../shared/context/locales"
import { useTranslation } from "react-i18next"

const InfoBoxes = () => {
    const { t } = useTranslation("translations")
    const { region } = useLocalesState()

    return (
        <Background color={BackgroundColor.Grey}>
            <Container>
                <Spacer variant={SpacerVariant.Small} />
                <div className="grid grid-cols-1 gap-x-5 gap-y-5 sm:grid-cols-2 lg:gap-x-6">
                    <div className="rounded-base bg-white p-5 text-center sm:p-7.5 lg:p-15">
                        <p className="mb-5 text-md font-semibold sm:mb-7 sm:text-lg lg:mb-10 lg:text-xl">
                            {t("infobox_order_text")}
                        </p>
                        <Button title={t("infobox_shop_button")} icon={Icons.cart} href={process.env.SHOP_URL} />
                        <p className="mt-5 whitespace-pre-wrap text-base sm:mt-7 sm:text-md lg:mt-10">
                            {t("infobox_delivery_text")}
                        </p>
                    </div>
                    <div className="rounded-base bg-white p-5 text-center sm:p-7.5 lg:p-15">
                        <p className="mb-5 whitespace-pre-wrap text-md font-semibold sm:mb-7 sm:text-lg lg:text-xl">
                            {t("infobox_questions_text")}
                        </p>
                        <div className="mb-5 flex flex-col sm:flex-row sm:items-center sm:justify-center">
                            <div className="text-base sm:mr-3 sm:text-md lg:text-lg">{t("infobox_phone")}</div>
                            <a
                                href={"tel:" + t("infobox_phone_url")}
                                className="text-md text-blue sm:text-lg lg:text-xl"
                            >
                                {t("infobox_phone_number")}
                            </a>
                        </div>
                        {region !== "CZ" && (
                            <div className="mb-3 flex flex-col sm:flex-row sm:items-center sm:justify-center">
                                <div className="text-base sm:mr-3 sm:text-md lg:text-lg">{t("infobox_whatsapp")}</div>
                                <a href={t("infobox_whatsapp_url")} className="text-md text-blue sm:text-lg lg:text-xl">
                                    {t("infobox_whatsapp_number")}
                                </a>
                            </div>
                        )}
                        {region !== "TR" && region !== "CZ" && (
                            <p className="mb-7 text-sm">{t("infobox_whatsapp_additional")}</p>
                        )}
                        <p className="whitespace-pre-wrap text-base sm:text-md">{t("infobox_working_hours")}</p>
                    </div>
                </div>
                <Spacer variant={SpacerVariant.Small} />
            </Container>
        </Background>
    )
}

InfoBoxes.schema = {
    name: "InfoBoxes",
    label: "Info Box",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/InfoBox.png`,
}

export default InfoBoxes
