import React from "react"
import classNames from "classnames"
import { options } from "../../../types"

export enum SpacerVariant {
    "Base" = "base",
    "Medium" = "md",
    "Small" = "sm",
    "XS" = "xs",
    "XXS" = "xxs",
    "None" = "none",
}

export interface SpacerProps {
    variant?: SpacerVariant
}

const Spacer = ({ variant = SpacerVariant.Base }: SpacerProps) => {
    if (variant === SpacerVariant.None) return null

    return (
        <div
            className={classNames({
                "pb-10 sm:pb-16 lg:pb-28 xl:pb-40": variant === SpacerVariant.Base,
                "pb-10 sm:pb-16 lg:pb-24": variant === SpacerVariant.Medium,
                "pb-7 sm:pb-10 lg:pb-16": variant === SpacerVariant.Small,
                "pb-6 sm:pb-7 lg:pb-10": variant === SpacerVariant.XS,
                "pb-5 sm:pb-6 lg:pb-7": variant === SpacerVariant.XXS,
            })}
        />
    )
}

export const spacerOptions = options<typeof SpacerVariant>(
    Object.fromEntries(Object.entries(SpacerVariant).filter(([key, value]) => value !== "none")) as typeof SpacerVariant
)

export default Spacer
