import { Background, BackgroundColor, Container, Spacer, SpacerVariant, spacerOptions } from "../components/layout"
import ImageEditor, { AspectRatio } from "../Image"
import { Repeater, Text, types } from "react-bricks/frontend"

import { Badges } from "../components/product"
import { IconTextListProps } from "./IconTextList"
import React from "react"

export interface ProductCustomProps {
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
    id?: string
    badge?: string
    secondaryBadge?: string
    deletedPrice?: string
    repeaterBottom?: {
        id: string
        props: IconTextListProps
        type: "IconTextList"
    }[]
}

const ProductCustom: types.Brick<ProductCustomProps> = ({
    spacerTop,
    spacerBottom,
    variantTop,
    variantBottom,
    id,
    badge,
    secondaryBadge,
    deletedPrice,
    repeaterBottom,
}) => {
    return (
        <Background color={BackgroundColor.Grey}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
                <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 lg:gap-x-6" id={id}>
                    <div>
                        <div className="sticky top-20 rounded-t-base bg-white p-5 sm:rounded-base lg:top-[104px] lg:p-7.5">
                            <ImageEditor
                                propName="image"
                                alt="Product"
                                maxWidth={648}
                                aspectRatio={AspectRatio["1/1"]}
                            />
                            {(badge || secondaryBadge) && <Badges badge={badge} secondaryBadge={secondaryBadge} />}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="relative rounded-b-base bg-white px-3.75 pb-3.75 text-base sm:rounded-base sm:px-7.5 sm:pb-7.5 lg:px-24 lg:pb-24">
                            <div className="pb-10 text-center">
                                <Text
                                    propName="headline"
                                    renderBlock={({ children }) => (
                                        <h4 className="mb-3 text-xl font-semibold text-blue sm:pt-16 sm:text-3xl lg:pt-24 lg:text-5xl">
                                            {children}
                                        </h4>
                                    )}
                                    placeholder="headline"
                                />
                                <Text
                                    propName="subheadline"
                                    renderBlock={({ children }) => (
                                        <p className="pb-5 text-md text-light-blue sm:pb-7 sm:text-lg lg:pb-10 lg:text-xl">
                                            {children}
                                        </p>
                                    )}
                                    placeholder="subheadline"
                                />
                                <span className="flex flex-row flex-wrap items-center justify-center text-md font-semibold sm:text-xl lg:text-2xl">
                                    {deletedPrice && (
                                        <del className="relative mr-2.5 text-sm font-normal text-dark-grey no-underline after:absolute after:-left-1 after:top-2.5 after:h-0.5 after:w-11 after:rotate-[-27deg] after:bg-dark-grey sm:text-lg">
                                            {deletedPrice}
                                        </del>
                                    )}
                                    <Text
                                        propName="price"
                                        renderBlock={({ children }) => <>{children}</>}
                                        placeholder="price"
                                    />
                                </span>
                                <Text
                                    propName="tax"
                                    renderBlock={({ children }) => (
                                        <small className="mt-3.5 block text-xs font-normal text-dark-grey lg:text-sm">
                                            {children}
                                        </small>
                                    )}
                                    placeholder="tax"
                                />
                            </div>
                            <div className="space-y-5 lg:space-y-7">
                                <Repeater propName="repeaterTop" itemProps={{ fullWidth: true }} />
                            </div>
                            {!!repeaterBottom?.length && (
                                <div className="space-y-5 pt-10 lg:space-y-7 lg:pt-16">
                                    <Repeater propName="repeaterBottom" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
            </Container>
        </Background>
    )
}

ProductCustom.schema = {
    name: "ProductCustom2",
    label: "Product Custom",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/ProductCustom.png`,
    repeaterItems: [
        {
            name: "repeaterTop",
            items: [
                {
                    type: "Forms",
                    label: "Form",
                    max: 1,
                },
                {
                    type: "SmallprintItem",
                    label: "Smallprint",
                    min: 0,
                },
                {
                    type: "Button",
                    label: "Button",
                    min: 0,
                },
            ],
        },

        {
            name: "repeaterBottom",
            items: [
                {
                    type: "IconTextList",
                    label: "Icon Text List",
                    min: 0,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "badge",
            label: "Badge",
            type: types.SideEditPropType.Text,
        },
        {
            name: "secondaryBadge",
            label: "Secondary badge",
            type: types.SideEditPropType.Text,
        },
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            name: "deletedPrice",
            label: "Deleted Price",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
    ],
    getDefaultProps: (): ProductCustomProps => ({
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Base,
        variantBottom: SpacerVariant.Base,
    }),
}

export default ProductCustom
