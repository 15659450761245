import {
    Background,
    BackgroundColor,
    Container,
    Spacer,
    SpacerVariant,
    backgroundOptions,
    spacerOptions,
} from "../components/layout"

import { AspectRatio } from "../video/FullWidthVideoBreak"
import { AspectRatio as ImageAspectRatio } from "../Image"
import ImageEditor from "../Image"
import React from "react"
import classNames from "classnames"
import { options } from "../../types"
import { types } from "react-bricks/frontend"

export enum Grid {
    "1 Image" = "grid-cols-1",
    "2 Images" = "grid-cols-2",
}

interface BasicImageProps {
    background: { color: BackgroundColor } | BackgroundColor
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
    grid: Grid
    aspectRatio: AspectRatio
}

const BasicImage: types.Brick<BasicImageProps> = ({
    background,
    spacerTop,
    spacerBottom,
    variantTop = SpacerVariant.Small,
    variantBottom = SpacerVariant.Small,
    grid,
    aspectRatio,
}) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
                <div
                    className={classNames("grid w-full", grid, {
                        "mx-auto w-full gap-1.5 sm:w-[80%] sm:gap-3 md:gap-3.5 lg:gap-5 xl:gap-6":
                            grid === Grid["2 Images"],
                    })}
                >
                    <ImageEditor
                        aspectRatio={grid === Grid["1 Image"] ? aspectRatio : ImageAspectRatio["4/5"]}
                        maxWidth={grid === Grid["1 Image"] ? 1440 : 564}
                        className="w-full rounded-base"
                        propName="image1"
                    />
                    <ImageEditor
                        aspectRatio={ImageAspectRatio["4/5"]}
                        maxWidth={564}
                        className={classNames("w-full rounded-base", { hidden: grid === Grid["1 Image"] })}
                        propName="image2"
                    />
                </div>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
            </Container>
        </Background>
    )
}

BasicImage.schema = {
    name: "BasicImage",
    label: "Basic Image",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/BasicImage.png`,
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [...spacerOptions].sort((a, b) => (a.value === SpacerVariant.Small ? -1 : 1)),
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [...spacerOptions].sort((a, b) => (a.value === SpacerVariant.Small ? -1 : 1)),
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
        {
            name: "grid",
            label: "Grid",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options<typeof Grid>(Grid),
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "aspectRatio",
            label: "Aspect Ratio",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options<typeof AspectRatio>(AspectRatio),
                display: types.OptionsDisplay.Select,
            },
            show: props => props.grid === Grid["1 Image"],
        },
    ],
    getDefaultProps: (): BasicImageProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Small,
        variantBottom: SpacerVariant.Small,
        aspectRatio: AspectRatio["5/2"],
        grid: Grid["1 Image"],
    }),
}

export default BasicImage
