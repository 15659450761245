import {
    Background,
    BackgroundColor,
    Container,
    Spacer,
    SpacerVariant,
    backgroundOptions,
    spacerOptions,
} from "../components/layout"
import { Color, TypographyColor } from "../components/typography"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import { GridSmCol } from "../components/grid"
import React from "react"

interface HeadlineCircleTextProps {
    background: { color: BackgroundColor } | BackgroundColor
    headline: TypographyElement[]
    text: TypographyElement[]
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
}

const HeadlineCircleText: types.Brick<HeadlineCircleTextProps> = ({
    background,
    spacerTop,
    spacerBottom,
    variantTop,
    variantBottom,
}) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
            <Container>
                <Color
                    color={
                        bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine
                            ? TypographyColor.White
                            : TypographyColor.Darkgrey
                    }
                >
                    <GridSmCol>
                        <Typography
                            propName="headline"
                            allowList={[
                                "h2",
                                "h3",
                                "h4",
                                "ul",
                                "small",
                                "sup",
                                "link",
                                "bold",
                                "checkList",
                                "superSized",
                            ]}
                        />
                        <div className="space-y-7 md:space-y-10">
                            <Typography
                                allowList={["h2", "h3", "h4", "ul", "small", "sup", "link", "bold", "checkList"]}
                            />
                            <Repeater propName="repeater" />
                        </div>
                    </GridSmCol>
                </Color>
            </Container>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
        </Background>
    )
}

HeadlineCircleText.schema = {
    name: "HeadlineCircleText",
    label: "Headline Circle Text",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/HeadlineCircleText.png`,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
    ],
    getDefaultProps: (): HeadlineCircleTextProps => ({
        background: { color: BackgroundColor.White },
        headline: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing.",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.",
                    },
                ],
            },
            {
                type: "small",
                children: [
                    {
                        text: "* Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                    },
                ],
            },
        ],
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Base,
        variantBottom: SpacerVariant.Base,
    }),
}

export default HeadlineCircleText
