import {
    Background,
    BackgroundColor,
    Container,
    Spacer,
    SpacerVariant,
    backgroundWhiteGreyBlueOptions,
    spacerOptions,
} from "../components/layout"
import React, { useRef, useState } from "react"
import { Repeater, Text, types } from "react-bricks/frontend"

import { CenterTextProps } from "../text/CenterText"
import { TabContentItemProps } from "./TabContentItem"
import { TypographyColor } from "../components/typography"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import { useScrollSize } from "../../hooks/use-scroll-size"
import { useWindowSize } from "../../hooks/use-window-size-admin"

export interface TabContentProps {
    background: { color: BackgroundColor } | BackgroundColor
    repeater?: {
        id: string
        props: TabContentItemProps
        type: "TabContentItem"
    }[]
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
}

const TabContent: types.Brick<TabContentProps> = ({
    repeater,
    spacerTop,
    spacerBottom,
    variantTop,
    variantBottom,
    background,
}) => {
    const [activeTab, setActiveTab] = useState(0)
    const ref = useRef<HTMLUListElement>(null)

    const changeTab = (id: string) => {
        setActiveTab(repeater?.findIndex(r => r.id === id) || 0)
        document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
    }

    const { width } = useWindowSize({ initializeWithValue: false, debounceDelay: 200 })

    const { width: tabWidth } = useScrollSize({ ref, initializeWithValue: false, debounceDelay: 200 })

    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
                <Repeater
                    propName="centerText"
                    itemProps={
                        {
                            spacerBottom: SpacerVariant.Medium,
                            color:
                                bgColor === BackgroundColor.DarkBlumine
                                    ? TypographyColor.White
                                    : TypographyColor.Darkgrey,
                        } as CenterTextProps
                    }
                />
                {repeater && repeater.length > 1 && (
                    <ul
                        ref={ref}
                        className={twMerge(
                            classNames(
                                "-mx-6.5 mb-10 flex flex-row gap-3 overflow-auto px-6.5 sm:mx-0 sm:mb-16 sm:justify-center sm:gap-7 md:gap-10 lg:gap-16",
                                {
                                    "justify-center": !!width && !!tabWidth && width >= tabWidth,
                                }
                            )
                        )}
                    >
                        {repeater.map((r, i) => {
                            return (
                                <li
                                    key={r.id}
                                    id={r.id}
                                    className={twMerge(
                                        "relative mb-3.5 flex min-w-fit items-center justify-center text-center text-md font-semibold opacity-30 sm:text-lg lg:text-xl",
                                        bgColor === BackgroundColor.DarkBlumine ? "text-white" : "text-blue",
                                        activeTab === i &&
                                            "opacity-100 after:absolute after:-bottom-2.5 after:h-1 after:w-[calc(100%_+_8px)] after:rounded-[2px]",
                                        activeTab === i &&
                                            (bgColor === BackgroundColor.DarkBlumine
                                                ? "after:bg-white"
                                                : "after:bg-blue")
                                    )}
                                >
                                    <Text
                                        propName={`title-${r.id}`}
                                        renderBlock={({ children }) => (
                                            <button className="h-full w-full" onClick={() => changeTab(r.id)}>
                                                {children}
                                            </button>
                                        )}
                                        renderPlaceholder={({ children }) => (
                                            <button className="h-full w-full" onClick={() => changeTab(r.id)}>
                                                {children}
                                            </button>
                                        )}
                                        placeholder="Lorem ipsum"
                                    />
                                </li>
                            )
                        })}
                    </ul>
                )}
                <Repeater
                    propName="repeater"
                    itemProps={
                        {
                            activeTab,
                            color:
                                bgColor === BackgroundColor.DarkBlumine
                                    ? TypographyColor.White
                                    : TypographyColor.Darkgrey,
                        } as TabContentItemProps
                    }
                />
                <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
            </Container>
        </Background>
    )
}

TabContent.schema = {
    name: "TabContent",
    label: "Tab Content",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/TabContent.png`,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TabContentItem",
                    label: "Tab",
                    min: 2,
                },
            ],
        },
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                display: types.OptionsDisplay.Color,
                options: backgroundWhiteGreyBlueOptions,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
    ],
    getDefaultProps: (): TabContentProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Base,
        variantBottom: SpacerVariant.Base,
    }),
}

export default TabContent
