import { LocalesReducer, initialState } from "./reducer"
import React, { useEffect, useReducer } from "react"
import { changeLanguage, toggleOverlay } from "./actions"
import { changeRegion, changeSlug } from "."

import { getCookie } from "../../utils/cookie"
import { regions } from "../../config/regions"

const LocalesStateContext = React.createContext()
const LocalesDispatchContext = React.createContext()

const useLocalesState = () => {
    const context = React.useContext(LocalesStateContext)
    if (context === undefined) {
        throw new Error("useLocalesState must be used within a LocalesProvider")
    }

    return context
}

const useLocalesDispatch = () => {
    const context = React.useContext(LocalesDispatchContext)
    if (context === undefined) {
        throw new Error("useLocalesDispatch must be used within a LocalesProvider")
    }

    return context
}

const LocalesProvider = props => {
    const { language, region, slug } = props
    const [state, dispatch] = useReducer(LocalesReducer, {
        ...initialState,
        language: language,
        region: region,
        slug: slug,
    })

    useEffect(() => {
        const search = new URLSearchParams(window.location.search)
        const region = search.get("region") || getCookie("__region__")

        if (
            !region ||
            !regions.filter(r => !process.env.NO_SITE_REGIONS?.includes(r.countryCode)).find(r => r.countryCode === region)
        )
            return toggleOverlay(dispatch)

        changeRegion(dispatch, { region: region })
    }, [])

    useEffect(() => {
        changeSlug(dispatch, { slug: slug })
    }, [slug])

    useEffect(() => {
        changeLanguage(dispatch, { language: language })
    }, [language])

    return (
        <LocalesStateContext.Provider value={state}>
            <LocalesDispatchContext.Provider value={dispatch}>{props.children}</LocalesDispatchContext.Provider>
        </LocalesStateContext.Provider>
    )
}

export { useLocalesState, useLocalesDispatch, LocalesProvider, LocalesStateContext }
