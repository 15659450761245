import {
    Background,
    BackgroundColor,
    Container,
    Spacer,
    SpacerVariant,
    backgroundOptions,
    spacerOptions,
} from "../components/layout"
import { Button, ButtonStyle } from "../components/button"
import React, { useState } from "react"
import { Repeater, types } from "react-bricks/frontend"

import { CenterTextProps } from "../text/CenterText"
import { IconTextGridProps } from "../icon-text/IconTextGrid"
import { TypographyColor } from "../components/typography"
import { useTranslation } from "react-i18next"

export interface ShowMoreProps {
    background: { color: BackgroundColor } | BackgroundColor
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
    initiallyVisible: number
}

const ShowMore: types.Brick<ShowMoreProps> = ({
    background,
    spacerTop,
    spacerBottom,
    variantTop,
    variantBottom,
    initiallyVisible,
}) => {
    const { t } = useTranslation("translations")
    const [showMore, setShowMore] = useState(false)

    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
                <Repeater
                    propName="centerText"
                    itemProps={
                        {
                            color:
                                bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine
                                    ? TypographyColor.White
                                    : TypographyColor.Darkgrey,
                        } as CenterTextProps
                    }
                />
                <Repeater
                    propName="repeater"
                    itemProps={
                        {
                            visible: !showMore ? initiallyVisible : undefined,
                        } as IconTextGridProps
                    }
                />
                <Spacer variant={SpacerVariant.Small} />
                <div className="flex justify-center">
                    <Button
                        onClick={() => setShowMore(showMore => !showMore)}
                        title={showMore ? t("icon_action_show_less") : t("icon_action_show_more")}
                        style={
                            bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine
                                ? ButtonStyle.Ghost
                                : ButtonStyle.Secondary
                        }
                    />
                </div>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
            </Container>
        </Background>
    )
}

ShowMore.schema = {
    name: "ShowMore",
    label: "Show More",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/ShowMore.png`,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "IconTextGrid",
                    label: "Icon Text Grid",
                    min: 1,
                    max: 1,
                },
            ],
        },
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "initiallyVisible",
            label: "Initially Visible",
            type: types.SideEditPropType.Number,
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
    ],
    getDefaultProps: (): ShowMoreProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Base,
        variantBottom: SpacerVariant.Base,
        initiallyVisible: 6,
    }),
}

export default ShowMore
