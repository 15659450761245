import { Align, TypographyAlign } from "../typography"
import { Background, BackgroundColor, Spacer, SpacerVariant, spacerOptions } from "../layout"
import ImageEditor, { AspectRatio } from "../../Image"
import { Pictograms, pictogramOptions } from "../../../types"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../../Typography"

import { IconText } from "../icon-text"
import React from "react"
import classNames from "classnames"
import { useCheckForEmptyText } from "../../../hooks/use-check-for-empty-text"

interface Text60Image40Props {
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
    headline: TypographyElement[]
    quoteTextBottom: TypographyElement[]
    reversePosition: boolean
    showIcon: boolean
    icon: Pictograms
}

const Text60Image40: types.Brick<Text60Image40Props> = ({
    spacerTop,
    spacerBottom,
    variantTop = SpacerVariant.Small,
    variantBottom = SpacerVariant.Small,
    reversePosition,
    quoteTextBottom,
    icon,
    showIcon,
}) => {
    const emptyText = useCheckForEmptyText(quoteTextBottom)

    return (
        <Background color={BackgroundColor.White}>
            <div className="hidden sm:block">
                <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
            </div>
            <Align align={TypographyAlign.Left}>
                <div className="sm:flex sm:items-center">
                    <div
                        className={classNames(
                            "grid w-full grid-cols-1 sm:mx-auto sm:gap-x-3.5 sm:gap-y-5 sm:px-6.5 md:px-10 lg:max-w-[1560px] lg:gap-x-5 lg:px-15",
                            {
                                "sm:grid-cols-[auto_1fr]": reversePosition,
                                "sm:grid-cols-[1fr_auto]": !reversePosition,
                            }
                        )}
                    >
                        <div
                            className={classNames(
                                "order-2 flex flex-col justify-center bg-background-tide px-6.5 py-10 sm:rounded-base sm:py-6 md:px-10 lg:py-10 xl:px-24",
                                {
                                    "sm:order-2": reversePosition,
                                    "sm:order-1": !reversePosition,
                                }
                            )}
                        >
                            <IconText icon={icon} className={classNames("mb-5", { hidden: !showIcon })} />
                            <Typography
                                block="quote-text-image"
                                allowList={[
                                    "h3",
                                    "h4",
                                    "h5",
                                    "ul",
                                    "link",
                                    "bold",
                                    "sup",
                                    "small",
                                    "italic",
                                    "checkList",
                                ]}
                                propName="headline"
                            />
                            {!emptyText && (
                                <div className="mt-5 md:mt-7 xl:mt-10">
                                    <Typography
                                        block="quote-text-image-bottom"
                                        allowList={["bold"]}
                                        propName="quoteTextBottom"
                                    />
                                </div>
                            )}
                            <Repeater
                                propName="repeater"
                                renderWrapper={children => (
                                    <div className="mt-10 flex flex-col space-y-5 self-start sm:space-y-7">
                                        {children}
                                    </div>
                                )}
                            />
                        </div>
                        <ImageEditor
                            aspectRatio={AspectRatio["1/1"]}
                            maxWidth={571}
                            className={classNames(
                                "order-1 w-full overflow-hidden sm:max-w-[354px] sm:rounded-base md:max-w-[390px] lg:max-w-[497px] xl:max-w-[571px]",
                                { "sm:order-2": !reversePosition, "sm:order-1": reversePosition }
                            )}
                        />
                    </div>
                </div>
            </Align>
            <div className="hidden sm:block">
                <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
            </div>
        </Background>
    )
}

Text60Image40.schema = {
    name: "Text60Image40",
    label: "Text 60 Image 40",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/Text60Image40.png`,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "reversePosition",
            label: "Reverse Position",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "showIcon",
            label: "Icon",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            show: props => props.showIcon,
            selectOptions: {
                options: pictogramOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [...spacerOptions].sort((a, b) => (a.value === SpacerVariant.Small ? -1 : 1)),
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [...spacerOptions].sort((a, b) => (a.value === SpacerVariant.Small ? -1 : 1)),
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
    ],
    getDefaultProps: (): Text60Image40Props => ({
        icon: Pictograms.vitality,
        showIcon: false,
        headline: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing.",
                    },
                ],
            },
        ],
        quoteTextBottom: [
            {
                type: "paragraph",
                children: [
                    {
                        bold: true,
                        text: "Lorem ipsum",
                    },
                    {
                        text: " dolor sit amet",
                    },
                ],
            },
        ],
        reversePosition: false,
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Small,
        variantBottom: SpacerVariant.Small,
    }),
}

export default Text60Image40
