import { Repeater, types, useAdminContext } from "react-bricks/frontend"
import { getCookie, setCookie } from "../../shared/utils/cookie"
import { useEffect, useState } from "react"

import React from "react"

interface ABTestBrickProps {
    testName: string
}

const ABTest: types.Brick<ABTestBrickProps> = ({ testName }) => {
    const [selectedComponent, setSelectedComponent] = useState<"A" | "B" | null>(null)

    const { isAdmin } = useAdminContext()

    const AB_TEST_COOKIE_NAME = "ABTestVariant"

    useEffect(() => {
        if (!selectedComponent) {
            const storedVariant = getCookie(AB_TEST_COOKIE_NAME)

            if (storedVariant === "A" || storedVariant === "B") {
                window.exponea.track("abtest", {
                    variant: storedVariant,
                    test: testName,
                })
                setSelectedComponent(storedVariant)
            } else {
                const variant: "A" | "B" = Math.random() < 0.5 ? "A" : "B"
                setCookie(AB_TEST_COOKIE_NAME, variant, 2592000)
                setSelectedComponent(variant)
                window.exponea.track("abtest", {
                    variant: variant,
                    test: testName,
                })
            }
        }
    }, [setSelectedComponent, testName, selectedComponent])

    if (!selectedComponent) return null

    return (
        <>
            {(isAdmin || selectedComponent === "A") && <Repeater propName="variantA" />}
            {(isAdmin || selectedComponent === "B") && <Repeater propName="variantB" />}
        </>
    )
}

export default ABTest

ABTest.schema = {
    name: "ABTest",
    label: "A/B Test",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/A_BTest.png`,
    sideEditProps: [
        {
            name: "testName",
            label: "Test Name",
            type: types.SideEditPropType.Text,
        },
    ],
    repeaterItems: [
        {
            name: "variantA",
            items: [
                {
                    type: "ProductMatrix",
                    label: "Product Matrix",
                    max: 1,
                },
            ],
        },
        {
            name: "variantB",
            items: [
                {
                    type: "Upselling",
                    label: "Upselling Component",
                    max: 1,
                },
            ],
        },
    ],
}
