import Badge from "../../components/trust/badge"
import React from "react"
import TrustedShopBadge from "../../components/trust/trusted-shop-badge"
import classNames from "classnames"
import { types } from "react-bricks/frontend"
import { useLocalesState } from "../../../shared/context/locales"

interface TrustProps {
    spacerTop: boolean
    spacerBottom: boolean
}

const Trust: types.Brick<TrustProps> = ({ spacerBottom, spacerTop }) => {
    const { language, region } = useLocalesState()

    return (
        <div
            className={classNames("justify-center sm:flex", {
                "mt-6 sm:mt-10": spacerTop,
                "mb-6 sm:mb-10": spacerBottom,
            })}
        >
            <div className="rounded-base border border-background-tide px-7 py-5">
                {language === "de" && region === "DE" ? <TrustedShopBadge size="sm" /> : <Badge size="sm" />}
            </div>
        </div>
    )
}

Trust.schema = {
    name: "Trust",
    label: "Trust Badge",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): TrustProps => ({
        spacerTop: false,
        spacerBottom: false,
    }),
}

export default Trust
