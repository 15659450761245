import { Text, types, useAdminContext } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../../Typography"

import Divider from "../../decorative/Divider"
import { Product } from "@lavita-io/international-sdk"
import React from "react"
import UpsellingBadgeText from "./BadgeText"
import UpsellingItemPrice from "./Price"
import UpsellingItemPriceAddition from "./PriceAddition"
import UpsellingPriceAsterisk from "./PriceAsterisk"
import UpsellingSmallprint from "./Smallprint"
import classNames from "classnames"
import { useCheckForEmptyText } from "../../../hooks/use-check-for-empty-text"
import { useTranslation } from "react-i18next"

export interface UpsellingItemProps {
    greyBox?: boolean
    badge?: boolean
    checklist: TypographyElement[]
    boxText: TypographyElement[]
    sku?: string
    index?: number
    products?: Product[]
    changeSelectedProduct: (index?: number) => void
    selectedProduct: number
    realPrice?: boolean
}

const UpsellingItem: types.Brick<UpsellingItemProps> = ({
    greyBox,
    badge,
    sku,
    products,
    index,
    changeSelectedProduct,
    selectedProduct,
    boxText,
    checklist,
    realPrice,
}) => {
    const { t } = useTranslation("translations")

    const product = products?.find(p => p.sku === sku)

    const { isAdmin } = useAdminContext()

    const emptyBoxText = useCheckForEmptyText(boxText)
    const emptyChecklist = useCheckForEmptyText(checklist)

    if (sku && !product && !isAdmin) return null

    return (
        <li key={index}>
            <button
                onClick={() => {
                    changeSelectedProduct(index)
                }}
                className={classNames(
                    "relative mb-3 w-full rounded-base border border-solid p-3.5 pl-3.5 text-left lg:p-7 lg:pl-7",
                    selectedProduct === index && "border-blue shadow-[0_0_3px_0_rgba(7,53,85,0)]",
                    selectedProduct !== index && "border-border-tide shadow-[0_0_3px_0_rgba(7,53,85,0)]"
                )}
            >
                <div
                    className={classNames(
                        "relative block cursor-pointer pl-8 before:absolute before:left-0 before:box-border before:h-5 before:w-5 before:rounded-full before:border before:content-['']",
                        selectedProduct === index &&
                            "before:border-blue before:bg-blue before:shadow-[inset_0_0_0_2px_#f2f0ee]",
                        selectedProduct !== index && "before:border-border-tide before:bg-white"
                    )}
                >
                    <div className="grid w-full grid-cols-[auto_auto] gap-y-3">
                        <div className="grid w-fit min-w-15 grid-cols-[auto] items-center gap-x-3 gap-y-1.5 lg:grid-cols-[auto_auto]">
                            <Text
                                propName="headline"
                                renderBlock={({ children }) => (
                                    <h4 className="text-base font-semibold sm:text-md lg:text-lg">{children}</h4>
                                )}
                                placeholder="Lorem ipsum"
                            />
                            {badge &&
                                (product?.subscription ? (
                                    <UpsellingBadgeText product={product} />
                                ) : (
                                    <div className="w-fit flex-shrink-0">
                                        <Text
                                            propName="badgeText"
                                            renderBlock={({ children }) => (
                                                <p className="text-box rounded-full bg-orange px-3.5 py-1.5 text-sm font-semibold text-white lg:text-base">
                                                    {children}
                                                </p>
                                            )}
                                            placeholder="Lorem ipsum"
                                        />
                                    </div>
                                ))}
                        </div>
                        <UpsellingItemPrice product={product} realPrice={realPrice} />
                        {product?.sku !== "SLP500" && (
                            <>
                                <Text
                                    propName="description"
                                    renderBlock={({ children }) => (
                                        <p className="text-sm sm:text-base lg:text-md">{children}</p>
                                    )}
                                    placeholder="Lorem ipsum"
                                />
                                {product?.sku === "LV2START" || product?.sku === "LVSTARTNA" ? (
                                    <div>
                                        <UpsellingItemPriceAddition product={product} realPrice={realPrice} />
                                    </div>
                                ) : (
                                    <div>
                                        <Text
                                            propName="total"
                                            renderBlock={({ children }) => (
                                                <p className="text-right text-sm sm:text-base lg:text-md">{children}</p>
                                            )}
                                            placeholder="Lorem ipsum"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        <UpsellingSmallprint product={product} />
                    </div>
                    {greyBox && selectedProduct !== index && (
                        <p
                            data-after=""
                            className="mt-3 text-sm font-semibold text-blue after:ml-1.5 after:font-['lavita-icon-font'] after:text-[10px] after:font-normal after:content-[attr(data-after)] lg:text-base lg:after:text-xs"
                        >
                            {t("upselling_further_information")}
                        </p>
                    )}
                </div>
                {greyBox && selectedProduct === index && (
                    <div className="-mb-3.5 -ml-3.5 -mr-3.5 mt-3.5 flex flex-col gap-3.5 rounded-b-base bg-background-tide px-5 py-3.5 lg:-mx-7 lg:-mb-7 lg:mt-7 lg:gap-7 lg:p-7">
                        {product?.subscription ? (
                            <UpsellingPriceAsterisk product={product} />
                        ) : (
                            !emptyBoxText && <Typography allowList={["bold"]} propName="boxText" block="box-text" />
                        )}
                        {!emptyChecklist && (
                            <Typography allowList={["smallCheckList"]} propName="checklist" block="copytext-small" />
                        )}
                    </div>
                )}
            </button>
            {index === 0 && <Divider text={t("divider_text")} />}
        </li>
    )
}

UpsellingItem.schema = {
    name: "UpsellingItem",
    label: "UpsellingItem",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "badge",
            label: "Badge",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "greyBox",
            label: "Grey Box",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "realPrice",
            label: "Real Price",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "sku",
            label: "SKU",
            type: types.SideEditPropType.Text,
        },
    ],
    getDefaultProps: (): Omit<UpsellingItemProps, "changeSelectedProduct" | "selectedProduct"> => ({
        badge: false,
        checklist: [
            {
                type: "smallCheckList",
                children: [
                    {
                        type: "CheckList Item",
                        children: [
                            {
                                text: "Lorem ipsum dolor",
                            },
                        ],
                    },
                ],
            },
        ],
        boxText: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                        bold: true,
                    },
                ],
            },
        ],
        realPrice: false,
    }),
}

export default UpsellingItem
