import {
    Background,
    BackgroundColor,
    Container,
    Spacer,
    SpacerVariant,
    backgroundOptions,
    spacerOptions,
} from "../components/layout"
import ImageEditor, { AspectRatio } from "../Image"
import { Repeater, types } from "react-bricks/frontend"

import { CenterTextProps } from "../text/CenterText"
import { GridIconTextProps } from "./GridIconText"
import React from "react"
import { TypographyColor } from "../components/typography"
import classNames from "classnames"

interface GridIconTextImageProps {
    background: { color: BackgroundColor } | BackgroundColor
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
}

const GridIconTextImage: types.Brick<GridIconTextImageProps> = ({
    background,
    spacerTop,
    spacerBottom,
    variantTop,
}) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
                <Repeater
                    propName="centerText"
                    itemProps={
                        {
                            color:
                                bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine
                                    ? TypographyColor.White
                                    : undefined,
                        } as CenterTextProps
                    }
                />
                <ul className="grid grid-cols-1 gap-x-5 gap-y-10 sm:grid-cols-3 lg:gap-x-6 lg:gap-y-15">
                    <Repeater
                        propName="repeater"
                        itemProps={
                            {
                                color:
                                    bgColor === BackgroundColor.Green || bgColor === BackgroundColor.DarkBlumine
                                        ? TypographyColor.White
                                        : undefined,
                            } as GridIconTextProps
                        }
                    />
                    <li
                        className={classNames(
                            "self-end sm:col-start-3 sm:row-start-2 sm:row-end-4 lg:col-start-2 lg:row-start-1",
                            { "-mb-10 sm:-mb-16 lg:-mb-28 xl:-mb-40": spacerBottom }
                        )}
                    >
                        <ImageEditor
                            maxWidth={717}
                            aspectRatio={AspectRatio["5/8"]}
                            className="aspect-h-8 aspect-w-5"
                        />
                    </li>
                </ul>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

GridIconTextImage.schema = {
    name: "GridIconTextImage",
    label: "Grid Icon Text Image",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/GridIconTextImage.png`,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "GridIconText",
                    label: "Icon Text",
                    min: 1,
                    max: 6,
                },
            ],
        },
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): GridIconTextImageProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Base,
    }),
}

export default GridIconTextImage
