import { closeOverlay, useOverlayDispatch } from "../../context/overlay"

import React from "react"
import SitemapStyles from "./sitemap.module.css"
import classNames from "classnames"
import { findTranslation } from "../../utils/find-translation"
import { sitemapMenu } from "../../config/sitemap"
import { useScrollPosition } from "../../hooks/use-scroll-position"

type SitemapProps = {
    language: string
    hidden?: boolean
}

const Sitemap = ({ hidden, language }: SitemapProps) => {
    const overlayDispatch = useOverlayDispatch()

    const { scrollTop } = useScrollPosition()

    const filteredSitemapMenu = sitemapMenu
        .map(column => column.filter(item => findTranslation(item, language)))
        .filter(column => column.length > 0)

    return (
        <ul
            id="sitemap"
            className={classNames(SitemapStyles.container, {
                [SitemapStyles.hidden]: hidden && scrollTop !== 0,
            })}
            style={{ "--columns": filteredSitemapMenu.length }}
        >
            {sitemapMenu.map((column, i) => (
                <div className={SitemapStyles.column} key={i}>
                    {column
                        .filter(item => findTranslation(item, language))
                        .map(item => (
                            <li key={item.id}>
                                <ul>
                                    <li className={SitemapStyles.headline}>
                                        {findTranslation(item, language).title}
                                    </li>
                                    {item.links.map(link => (
                                        <li key={link.id}>
                                            <a
                                                href={findTranslation(link, language).url}
                                                onClick={() => closeOverlay(overlayDispatch)}
                                            >
                                                {findTranslation(link, language).title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                </div>
            ))}
        </ul>
    )
}

export default Sitemap
