import { Background, BackgroundColor, Container, backgroundGreyScaleColorsOptions } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import React from "react"
import { TrustReviewProps } from "./TrustReview"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"

interface TrustReviewsStripeProps {
    background?: { color: BackgroundColor } | BackgroundColor
    repeater?: {
        id: string
        props: TrustReviewProps
        type: "TrustReviewItem"
    }[]
}

const TrustReviewsStripe: types.Brick<TrustReviewsStripeProps> = ({ background, repeater }) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <div
                    className={twMerge(
                        classNames(
                            "box-content grid grow grid-cols-1 gap-y-10 py-7 sm:justify-center lg:py-10",
                            { "sm:grid-cols-3": repeater?.length === 3 },
                            { "sm:grid-cols-2": repeater?.length === 2 }
                        )
                    )}
                >
                    <Repeater propName="repeater" />
                </div>
            </Container>
        </Background>
    )
}

TrustReviewsStripe.schema = {
    name: "TrustReviewsStripe",
    label: "Trust Reviews",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/TrustReviewItem.png`,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TrustReviewItem",
                    label: "Trust Review Item",
                    max: 3,
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundGreyScaleColorsOptions,
                display: types.OptionsDisplay.Color,
            },
        },
    ],
    getDefaultProps: (): TrustReviewsStripeProps => ({
        background: { color: BackgroundColor.White },
    }),
}

export default TrustReviewsStripe
