import React from "react"
import { RenderElementProps } from "slate-react"

const CenteredUl = ({ children, attributes }: RenderElementProps) => {
    return (
        <ul
            {...attributes}
            className="mb-4 list-none justify-center gap-7 text-md last:mb-0 sm:mb-5 sm:flex sm:text-lg lg:text-xl"
        >
            {children}
        </ul>
    )
}

export default CenteredUl
