import { Align, Color, TypographyAlign, TypographyColor } from "../components/typography"
import {
    Background,
    BackgroundColor,
    CardBackgroundColor,
    Container,
    Spacer,
    SpacerVariant,
    backgroundGreyScaleColorsOptions,
    cardBackgroundOptions,
    spacerOptions,
} from "../components/layout"
import { Pictograms, pictogramOptions } from "../../types"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import { IconShadowCard } from "../components/icon-text"
import React from "react"

interface IconTextShadowCardProps {
    icon: Pictograms
    background: { color: BackgroundColor } | BackgroundColor
    cardBackground: { color: CardBackgroundColor } | CardBackgroundColor
    text: TypographyElement[]
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
}

const IconTextShadowCard: types.Brick<IconTextShadowCardProps> = ({
    icon,
    background,
    cardBackground,
    spacerTop,
    spacerBottom,
    variantTop = SpacerVariant.Small,
    variantBottom = SpacerVariant.Medium,
}) => {
    const bgColor = typeof background === "object" ? background.color : background
    const cardBgColor = typeof cardBackground === "object" ? cardBackground.color : cardBackground

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
                <IconShadowCard icon={icon} background={cardBgColor}>
                    <Align align={TypographyAlign.Center} className="space-y-7 md:space-y-10">
                        <Color color={cardBgColor === CardBackgroundColor.Green ? TypographyColor.White : undefined}>
                            <Typography block="copytext-small" allowList={["small", "sup", "link", "bold"]} />
                        </Color>
                        <Repeater propName="repeater" />
                    </Align>
                </IconShadowCard>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
            </Container>
        </Background>
    )
}

IconTextShadowCard.schema = {
    name: "IconTextShadowCard",
    label: "Icon Text Shadow Card",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/IconTextShadowCard.png`,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: pictogramOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundGreyScaleColorsOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            name: "cardBackground",
            label: "Card Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: cardBackgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [...spacerOptions].sort((a, b) => (a.value === SpacerVariant.Small ? -1 : 1)),
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [...spacerOptions].sort((a, b) => (a.value === SpacerVariant.Medium ? -1 : 1)),
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
    ],
    getDefaultProps: (): IconTextShadowCardProps => ({
        icon: Pictograms.vitality,
        background: { color: BackgroundColor.White },
        cardBackground: { color: CardBackgroundColor.White },
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                    },
                ],
            },
        ],
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Small,
        variantBottom: SpacerVariant.Medium,
    }),
}

export default IconTextShadowCard
