import {
    Background,
    BackgroundColor,
    Container,
    Spacer,
    SpacerVariant,
    backgroundGreyScaleColorsOptions,
    spacerOptions,
} from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import { FixedWidthSlider } from "../components/slider"
import { QuoteTextProps } from "./QuoteText"
import React from "react"

interface QuoteTextSliderProps {
    background: { color: BackgroundColor } | BackgroundColor
    repeater?: {
        id: string
        props: QuoteTextProps
        type: "QuoteText"
    }[]
    spacerTop: boolean
    spacerBottom: boolean
    variantTop?: SpacerVariant
    variantBottom?: SpacerVariant
}

const QuoteTextSlider: types.Brick<QuoteTextSliderProps> = ({
    background,
    repeater,
    spacerTop,
    spacerBottom,
    variantTop,
    variantBottom,
}) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Spacer variant={!spacerTop ? SpacerVariant.None : variantTop} />
            <Repeater propName="centerText" renderWrapper={children => <Container>{children}</Container>} />
            <FixedWidthSlider length={repeater?.length || 0}>
                <Repeater propName="repeater" itemProps={{ bgColor: bgColor }} />
            </FixedWidthSlider>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : variantBottom} />
        </Background>
    )
}

QuoteTextSlider.schema = {
    name: "QuoteTextSlider",
    label: "Quote Text Slider",
    previewImageUrl: `${process.env.IMG_CDN_URL_ASSETS}/lavita.de/startseite/screenshots_bricks/QuoteTextSlider.png`,
    repeaterItems: [
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
        {
            name: "repeater",
            items: [
                {
                    type: "QuoteText",
                    label: "Quote Text Item",
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundGreyScaleColorsOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantTop",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerTop === true,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "variantBottom",
                    label: "Spacer Variant",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: spacerOptions,
                        display: types.OptionsDisplay.Select,
                    },
                    show: props => props.spacerBottom === true,
                },
            ],
        },
    ],
    getDefaultProps: (): QuoteTextSliderProps => ({
        background: { color: BackgroundColor.White },
        spacerTop: true,
        spacerBottom: true,
        variantTop: SpacerVariant.Base,
        variantBottom: SpacerVariant.Base,
    }),
}

export default QuoteTextSlider
